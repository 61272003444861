// store/modules/device.js
import { EDeviceTypeName, ELocalStorageKey } from '/@/types/enums'
import { getDockList, startFlyNow } from '/@/api/project'
import {
  ControlSource,
  DeviceOsdCamera,
  DrcStateEnum,
  OnlineDevice,
  EModeCode,
  EDockModeCode,
} from '/@/types/device'
// 时间戳转换
const formatTimestamp = (timestamp: any) => {
  const date = new Date(timestamp)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${month}-${day} ${hours}:${minutes}`
}
export default {
  namespaced: true, // 启用命名空间
  state: {
    devices: [], // 存储设备信息的数组
    dockList: {
      data: []
    },
    dockAndAir: {}
    // onLineUav: {
    //   data: []
    // },
    // goFltDock: {
    //   data: []
    // },
    // onlineDevices: {
    //   data: []
    // },
    // onlineDocks: {
    //   data: []
    // },
  },
  mutations: {
    // dockList.data为最近执行的任务信息及设备信息总和
    // dockAndAir  机场及飞机名称sn
    setDockListAndAir (state, obj) {
      state.dockList.data = obj.dock
      state.dockAndAir = {
        air_sn: obj.res.data[0].device_dto.child_device_sn,
        air_name: obj.res.data[0].device_dto.children.device_name,
        dock_name: obj.res.data[0].device_dto.device_name,
        dock_sn: obj.res.data[0].device_dto.device_sn,
      }
    },
    addDevice (state, device) {
      state.devices.push(device)
    },
    removeDevice (state, deviceId) {
      state.devices = state.devices.filter(device => device.id !== deviceId)
    },
    updateDevice (state, updatedDevice) {
      const index = state.devices.findIndex(device => device.id === updatedDevice.id)
      if (index !== -1) {
        state.devices[index] = updatedDevice
      }
    }
  },
  actions: {
    // 获取最新计划的数据和绑定设备信息
    async getDockDevices ({ commit }) {
      const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!
      await getDockList(workspaceId).then((res) => {
        if (res.code !== 0) {
          return
        }
        console.log(res, '返回长什么样')

        const temp = []
        res.data.forEach((item: any) => {
          // 遍历计划信息
          const gateway = item.device_dto // 机场
          const child = gateway.children // 无人机
          const device: any = {
            // 无人机信息
            model: child?.device_name, // 无人机名称
            callsign: child?.nickname, // 无人机昵称
            sn: child?.device_sn, // 无人机sn
            cameras_list: child?.capacity_device_dto,
            mode: EModeCode.Disconnected, // 代表断开连接
            gateway: {
              // 网关，机场信息
              model: gateway?.device_name, // 机场名称
              callsign: gateway?.nickname, // 机场昵称
              sn: gateway?.device_sn, // 机场sn
              domain: gateway?.domain, // 设备类型
              cameras_list: gateway?.capacity_device_dto,
            },
            payload: [], // 负载
          }
          child?.payloads_list.forEach((payload: any) => {
            // 遍历无人机的负载列表
            device.payload.push({
              // 存放到无人机信息对象中
              index: payload.index,
              model: payload.model,
              payload_name: payload.payload_name, // 负载名称：xxx相机
              payload_sn: payload.payload_sn,
              control_source: payload.control_source, // ?
              payload_index: payload.payload_index, // ?
            })
          })

          // 最近执行的任务信息及设备信息总和
          temp.push({
            // 机场
            start_time: formatTimestamp(item.start_time),
            end_time: formatTimestamp(item.start_time),
            nick: item.nick,
            status: item.status,
            msg: device,
            id: item.id,
          })
        })

        commit('setDockListAndAir', {
          dock: temp,
          res: res
        })
      })
    }
  },
  getters: {
    dockList: state => state.dockList
  }
}
