<template>
  <div class="all">
    <div class="all-top">
      <div class="all-top-title">
        机构管理
      </div>

      <div>
        <el-button style="margin-right: 10px;" @click="addShowFunc">新增机构</el-button>
        <el-input v-model="seachContent" style="width: 240px" placeholder="请输入关键词" :suffix-icon="Search" />
      </div>
    </div>
    <el-table border :header-cell-style="{ background: '#f8f8fa', textAlign: 'left' }" :data="tableData" stripe
      style="width: 100%">
      <el-table-column prop="workspace_name" label="组织名称" width="180" />
      <el-table-column prop="bind_code" label="设备绑定码" width="180" />
      <el-table-column prop="workspace_desc" label="描述" />
      <el-table-column prop="handle" label="操作" >
        <template #default="scope: any">
          <div class="opration">
          <el-button
            v-for="(theItem, theIndex) in scope.row.handle"
            :key="theIndex"
            text
            class="elBtn"
            :class="[theItem === 'delete' || theItem === 'logOut' ? 'red' : 'blue']"
            size="small"
            @click="allHandle(theItem, scope.row)"
            >{{ getFunctionName(theItem) }}</el-button
          >
        </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="all-pagination">
      <el-config-provider :locale="locale">
        <el-pagination :current-page="page" :page-size="pageSize" :page-sizes="[5, 10]"
          layout="total, sizes, prev, pager, next" :total="total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </el-config-provider>

    </div>
            <!-- 添加编辑框 -->
        <el-dialog :destroy-on-close="true" v-model="addShow" title="新增权限" width="600" @close="closeDialog">
            <el-form :model="addForm" label-width="auto" ref="ruleFormRef" :rules="rules">
                <el-form-item label="组织名称" prop="workspaceName">
                    <el-input size="large" v-model="addForm.workspaceName" placeholder="请输入组织名称"></el-input>
                </el-form-item>
                <el-form-item label="组织描述" prop="workspaceDesc">
                    <el-input size="large" v-model="addForm.workspaceDesc" placeholder="请输入组织名称"></el-input>
                </el-form-item>
                <el-form-item label="设备绑定码" prop="bindCode">
                    <el-input size="large" v-model="addForm.bindCode" placeholder="请输入组织名称"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addShow = false">取消</el-button>
                    <el-button class="blue" type="primary" @click="comfirmAdd(ruleFormRef)"> 确认 </el-button>
                </span>
            </template>
        </el-dialog>
         <!-- 删除组织 -->
        <ConfirmDialog v-if="tipDialog" title="提示" :data="{ width: 600, text: 'delete' }" @confirm="delExciting"
            @close="closetip">
        </ConfirmDialog>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, shallowRef, reactive } from 'vue'
import { addOrgan, editOrgan, queryOrgan, delOrgan } from '/@/api/right'
import { Search } from '@element-plus/icons-vue'
import type { FormInstance, FormRules } from 'element-plus'
import { message } from 'ant-design-vue'
import ConfirmDialog from '/@/components/ConfirmDialog.vue'
// ElConfigProvider 组件
import { ElConfigProvider } from 'element-plus'
// 引入中文包
import locale from 'element-plus/es/locale/lang/zh-cn'
import router from '/@/router'

const page = ref(1)
const pageSize = ref(10)
const total = ref(0)

const ruleFormRef = ref()
const addShow = ref(false)
const addForm = ref({
  workspaceName: '', // 组织名称
  // platformName: '',
  workspaceDesc: '', // 描述
  bindCode: '', // 设备绑定码
})
const rules = reactive<any>({
  workspaceName: [{ required: true, message: '请输入', trigger: 'blur' }],
  workspaceDesc: [{ required: true, message: '请输入', trigger: 'blur' }],
  bindCode: [{ required: true, message: '请输入', trigger: 'blur' }],

})

const tipDialog = ref(false)

const seachContent = ref('')
const tableData: any = ref([])

// 打开删除
const openDel = () => {
  tipDialog.value = true
}
// 关闭删除
const closetip = () => {
  tipDialog.value = false
}
// 删除组织
const delExciting = async () => {
  const res = await delOrgan({
    id: addForm.value.id
  })

  if (res.message == 'success') {
    message.success('删除成功!')
  }
  tipDialog.value = false
  getTableData()
}

// 展示添加组织
const addShowFunc = () => {
  addForm.value = {
    workspaceName: '', // 组织名称
    // platformName: '',
    workspaceDesc: '', // 描述
    bindCode: '', // 设备绑定码
  }
  addShow.value = true
}

// 确认添加组织
const comfirmAdd = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      let res
      if (addForm.value.id) { // 如果有id就是编辑
        res = await editOrgan({
          ...addForm.value
        })
      } else {
        res = await addOrgan({
          ...addForm.value
        })
      }
      if (res.message == 'success') {
        message.success('保存成功!')
      }
      addShow.value = false
      getTableData()
    } else {
      console.log('error submit!', fields)
    }
  })
}

const getFunctionName = (item: string) => {
  switch (item) {
    case 'add':
      return ' 新增'
    case 'account':
      return '账号管理'
    case 'edit':
      return ' 编辑'
    case 'delete':
      return ' 删除'

    default:
      return item
  }
}

// 中删及方法的编转辑除;
const allHandle = (type: string, row: any) => {
  // emits("handle", { type, row });
  console.log(type, row)

  switch (type) {
    case 'account':
      console.log('跳转')
      router.push({ name: 'organization-menber', query: { id: row.workspace_id } })
      break
    case 'edit':
      addForm.value = {
        id: row.id,
        workspaceName: row.workspace_name,
        workspaceDesc: row.workspace_desc,
        bindCode: row.bind_code,
      }

      addShow.value = true
      break
    case 'delete':
      addForm.value = {
        id: row.id,

      }
      openDel()
      break

    default:
      break
  }
}

// 分页器方法
const handleSizeChange = (val: number) => {
  pageSize.value = val
  getTableData()
}
const handleCurrentChange = (val: number) => {
  page.value = val
  getTableData()
}
// 获取组织数据
const getTableData = async () => {
  const res = await queryOrgan({
    page: page.value,
    pageSize: pageSize.value,
  })

  console.log(res, '组织')
  if (res.message == 'success') {
    total.value = res.data.pagination.total
    res.data.list.forEach(item => {
      item.handle = ['account', 'edit', 'delete']
    })
    tableData.value = res.data.list
  }
}

onMounted(() => {
  getTableData()
})
</script>

<style scoped lang="scss">
  .gray {
    color: #999999;
  }

  .blue {
    color: #0c5eff;
  }

  .red {
    color: #ff3030;
  }

  .green {
    color: #24ca62;
  }

  .elBtn {
    font-size: 14px;
    padding: 0;
  }
.all {
  margin: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;

  .all-top {

    display: flex;
    justify-content: space-between;

    .all-top-title {
      color: #19223d;
      font-family: 'PingFang SC';
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  .all-pagination {
    margin-top: 10px;
  }

}
</style>
