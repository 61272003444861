import { message } from 'ant-design-vue'
import request, { IPage, IWorkspaceResponse, IListWorkspaceResponse } from '/@/api/http/request'
import { TaskType, TaskStatus, OutOfControlAction } from '/@/types/task'
import { WaylineType } from '/@/types/wayline'

const HTTP_PREFIX = '/wayline/api/v1'

// 获取最新任务及执行任务的设备信息
export const getDockList = async function (wid: string): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/workspaces/${wid}/projectJobs`
  const result = await request.get(url)
  return result.data
}

// 立即飞行
export const startFlyNow = async function (wid: string, body: any): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/workspaces/${wid}/immediate-flight-tasks`
  const result = await request.post(url, body)
  return result.data
}
