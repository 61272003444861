<template>
    <el-dialog v-model="dialogVisible" :title="props.title" :width="props.data.width ? props.data.width : '600px'"
        @close="close">
        <div v-if="props.data.text == 'delete'" class="deletebox">
            <img src="/src/assets/warning.png" alt="" />
            删除后不可恢复，确定删除？
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button style="width: 92px" @click="close">取消</el-button>
                <el-button style="width: 92px" class="blue" type="primary" @click="confirm">
                    确认
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps<{ title: string; data: any }>()
const emits = defineEmits<{
    (e: 'close'): void;
    (e: 'confirm'): void;
}>()
const dialogVisible = ref(true)

const close = () => {
  emits('close')
}
const confirm = () => {
  emits('confirm')
}
</script>

<style lang="scss" scoped>
.blue {
    background-color: #126bff;
}

.deletebox {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 40px;
        height: 40px;
        margin-right: 15px;
    }
}
</style>
