<!-- 统计首页 -->
<template>
  <div class="all">
    <div class="all-flyCount">
      <div class="all-left">
        <div class="all-flyCount-item" style="margin-right: 20px;">
        <div class="text1">巡检无人机数量</div>
        <div class="text2">{{ bigCount }}</div>
      </div>

      <div class="all-flyCount-item" style="margin-right: 20px;">
        <div class="text1">旅拍无人机数量</div>
        <div class="text3">{{ smallCount }}</div>
      </div>
      <div class="all-flyCount-item" style="margin-right: 20px;">
        <div class="text1">巡检无人机任务数量</div>
        <div class="text2">{{ bigTaskCount }}</div>
      </div>
      <div class="all-flyCount-item">
        <div class="text1">旅拍无人机任务数量</div>
        <div class="text3">{{ smallTaskCount }}</div>
      </div>
      </div>

      <div class="all-right">
         <div ref="zhu" class="all-zhu"></div>
      </div>
    </div>

    <div class="all-bing">
      <div ref="small" class="all-bing-item"></div>
      <div ref="big" class="all-bing-item"></div>

    </div>
  </div>
</template>

<script setup lang="ts">
import * as echarts from 'echarts'
import { ref, onMounted, onUnmounted } from 'vue'
import { getFlyNum, getFlyJob, getFlyWayline } from '/@/api/right'
import { ELocalStorageKey } from '/@/types/enums'
// import { FirstApi } from '@/api/first'
// import message from '@/utils/message'

const workspaceId = ref(localStorage.getItem(ELocalStorageKey.WorkspaceId)!)
const bigCount = ref(0) // 数量
const smallCount = ref(0)

const bigTaskCount = ref(0) // 任务数量
const smallTaskCount = ref(0)// 任务数量

const bigTaskWayCount = ref([]) // 航线任务数量
const smallTaskWayCount = ref([])// 航线 任务数量

const small = ref() // 小飞机饼图
const big = ref() // 大飞机饼图
const zhu = ref() // 柱状图

const page = ref(1) // 用于记录班级评分状态变化
const pageSize = ref(5) // 用于记录班级评分状态变化

const total = ref(0) // 班级评分总数
const totalPage = ref(0) // 班级评分总数

let timer
let timmerBig

// 旅拍飞机
const optionSamll = {
  title: {
    text: `旅拍航线飞行次数(${page.value}/${totalPage.value})`,
  },
  tooltip: {
    trigger: 'item',
    formatter: '{b0}: {c0}次',
  },
  legend: {
    // 图例
    orient: 'vertical',
    // top: '5%',
    left: 'right',
  },
  series: [
    {
      name: `旅拍航线飞行次数(${page.value}/${totalPage.value})`,
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2,
      },
      label: {
        show: false,
        position: 'center',
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 40,
          fontWeight: 'bold',
        },
      },
      labelLine: {
        show: false,
      },
      data: [],
    },
  ],
}
// 巡检飞机
const optionBig = {
  title: {
    text: `巡检航线飞行次数(${page.value}/${totalPage.value})`,
  },
  tooltip: {
    trigger: 'item',
    formatter: '{b0}: {c0}次',
  },
  legend: {
    // 图例
    orient: 'vertical',
    // top: '5%',
    left: 'right',
  },
  series: [
    {
      name: '巡检飞机航线飞行次数',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2,
      },
      label: {
        show: false,
        position: 'center',
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 40,
          fontWeight: 'bold',
        },
      },
      labelLine: {
        show: false,
      },
      data: [],
    },
  ],
}

// 柱状图
const optionZhu = {
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      data: [120, 200, 150, 80, 70, 110, 130],
      type: 'bar'
    }
  ]
}

const getFlyNumFunc = async () => {
  const res = await getFlyNum(workspaceId.value)
  if (res.message === 'success') {
    bigCount.value = res.data.device
    smallCount.value = res.data.drone
  }
}

const getFlyJobFunc = async () => {
  const res = await getFlyJob(workspaceId.value)
  if (res.message === 'success') {
    bigTaskCount.value = res.data.device
    smallTaskCount.value = res.data.drone
    // optionZhu.series[0].data.push(smallTaskCount.value)
    // optionZhu.series[0].data.push(bigTaskCount.value)

    // const zhuChart = echarts.init(zhu.value)
    // zhuChart.setOption(optionZhu)

    console.log('柱状图')
  }
}

const getFlyWaylineFunc = async () => {
  const res = await getFlyWayline(workspaceId.value, {
    page: page.value,
    pageSize: pageSize.value
  })

  if (res.message === 'success') {
    bigTaskWayCount.value = res.data.data.map(item => {
      return {
        name: item.fileName,
        value: item.device,
      }
    })
    smallTaskWayCount.value = res.data.data.map(item => {
      return {
        name: item.fileName,
        value: item.drone,
      }
    })

    total.value = res.data.total
    totalPage.value = Math.floor(Number(res.data.total) / Number(pageSize.value)) + 1

    optionSamll.series[0].data = smallTaskWayCount.value
    optionSamll.title.text = `旅拍航线飞行次数(${page.value}/${totalPage.value})`
    const smallChart = echarts.init(small.value)
    smallChart.setOption(optionSamll)

    optionBig.series[0].data = bigTaskWayCount.value
    optionBig.title.text = `巡检航线飞行次数(${page.value}/${totalPage.value})`
    const bigChart = echarts.init(big.value)
    bigChart.setOption(optionBig)
  }
}

onMounted(() => {
  getFlyNumFunc()
  getFlyJobFunc()
  getFlyWaylineFunc()
  // getCourse()
  // getStudent()

  // getClassTime()
  // getScore();

  timer = setInterval(() => {
    if (page.value == totalPage.value) {
      page.value = 1
    } else {
      page.value++
    }
    getFlyWaylineFunc()
  }, 3000)
})

onUnmounted(() => {
  clearInterval(timer)
})
</script>

<style scoped lang="scss">

.all {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 20px;
  padding: 20px;
  .all-flyCount{
    display: flex;

    .all-left{
      display: flex;
    .all-flyCount-item{
       background-color: #f0f0f1;
       border-radius: 6px;
       padding: 20px;
        text-align: center;
       .text1{
        font-size: 20px;
       }
       .text2{
        font-size: 26px;
        color: #4bb5ee;

       }
       .text3{
        font-size: 26px;
        color: #bc1616;
       }
   }
    }

    // .all-right{
    //   width: 600px;
    //   height: 600px;
    //   .all-zhu{
    //     width: 100%;
    //     height: 100%;
    //   }
    // }

  }

  .all-bing{
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin-top: 20px;
    width: 100%;
    height: 640px;
    &-item {
      background-color: #f0f0f1;
      // flex: 1;
      width: 49%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 20px;
      font-weight: 400;
      color: #2b2a2a
    }
  }

}
</style>
