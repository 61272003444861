
export default {
  namespaced: true, // 启用命名空间
  state: {
    canview: [], // 存储可以访问的页面

  },
  mutations: {
    SET_CANVIEW (state, arr) {
      console.log('设置权限', arr)

      state.canview = arr
    }
  },
  actions: {
    // 获取最新计划的数据和绑定设备信息

  },
  getters: {
    canview: state => state.canview
  }
}
