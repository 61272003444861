<template>
    <div class="courseAll">
        <div class="courseAll-header">
            <el-breadcrumb :separator-icon="ArrowRight">
                <el-breadcrumb-item :to="{ path: '/organization-manage' }">机构管理</el-breadcrumb-item>
                <el-breadcrumb-item>账号管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="all">
            <div class="all-header">
                <div class="header-btn" @click="addMenbersShow">添加人员 </div>
                <!-- <div class="header-btn ne">修改角色 </div> -->
                <div class="header-btn ne" @click="delMembers">删除 </div>

            </div>
            <div class="all-body">
                <div class="table flex-display flex-column">
   <a-locale-provider :locale="zhCN">
                    <a-table :columns="columns" :data-source="tableData"
                        :row-selection="{ selectedRowKeys: state.selectedRowKeys, selectedRows: state.selectedRows, onChange: onSelectChange }"
                        :pagination="false" @change="refreshData" row-key="user_id"
                        :rowClassName="(record, index) => ((index % 2) === 0 ? 'table-striped' : null)"
                        :scroll="{ x: 1500, y: 600 }">
                        <!-- 自定义 user_grade 列的插槽 -->
                        <template #user_grade="{ text, record }">
                            <div>
                                {{ getRoleName(text) }}
                            </div>
                        </template>

                        <template #action="{ record }">
                            <div class="editable-row-operations">

                                <span  class="fz18 flex-align-center flex-row" style="color: #2d8cf0">
                                    <!-- <EditOutlined @click="edit(record)" /> -->
                                    <img class="ic" :src="editicon" alt="" @click="edit(record)">
                                    <!-- <DeleteOutlined style="margin-left: 10px;" @click="deleteRow(record)" /> -->
                                </span>
                            </div>
                        </template>

                    </a-table>
                    <div class="fot">
                        <a-pagination show-size-changer v-model:current="body.page" v-model:pageSize="body.page_size"
                            :total="body.total"   @change="onShowPageChange"
                            :page-size-options="pageSizeOptions" />

                    </div>
                     </a-locale-provider>

                </div>

            </div>
              <!-- 添加\编辑角色 -->
        <el-dialog :destroy-on-close="true" v-model="addMemberShow" title="新增权限" width="600" >
            <el-form :model="addForm" label-width="auto" ref="formRef" :rules="rules">
                <el-form-item label="人员名称" prop="name">
                    <el-input size="large" v-model="addForm.name" placeholder="请输入人员名称"></el-input>
                </el-form-item>
                <el-form-item label="用户账号" prop="username">
                    <el-input size="large" :disabled="isEdit" v-model="addForm.username" placeholder="请输入人员名称"></el-input>
                </el-form-item>
                <el-form-item label="用户密码" prop="password">
                    <el-input size="large" v-model="addForm.password" placeholder="请输入人员名称"></el-input>
                </el-form-item>
                <el-form-item label="用户权限" prop="user_grade">
                    <el-radio-group v-model="addForm.user_grade">
                                <el-radio v-for="item in roleList" :key="item.id" :value="item.id" :label="item.role_name" />

                            </el-radio-group>

                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addShow = false">取消</el-button>
                    <el-button class="blue" type="primary" @click="addFormSubmit(formRef)"> 确认 </el-button>
                </span>
            </template>
        </el-dialog>
            <!-- 确认删除弹窗 -->
            <a-modal v-model:visible="delMemberShow" title="确认删除">
                <p>
                    确认删除所选内容？
                </p>
                <template #footer>
                    <a-button @click="delMemberShow = false">取消</a-button>
                    <a-button @click="delSubmit" type="primary">确认</a-button>
                </template>
            </a-modal>
        </div>

    </div>
</template>

<script lang="ts" setup>
import { ArrowRight } from '@element-plus/icons-vue'
import { useRoute } from 'vue-router'
import { onMounted, ref, reactive, Ref, UnwrapRef, toRaw } from 'vue'
import { getAllUsersInfo, updateUserInfo, addUserInfo, delUserInfo } from '/@/api/manage'
import { message, PaginationProps } from 'ant-design-vue'
import { TableState } from 'ant-design-vue/lib/table/interface'
import { queryRole, queryAuById, editAu, delRole, addRole } from '/@/api/right'
import { ELocalStorageKey } from '/@/types'
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import editicon from '/@/assets/item/编组 6@2x.png'
import { EditOutlined, CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import type { FormInstance, FormRules } from 'element-plus'

interface Member {
    user_id: string
    username: string
    user_type: string
    workspace_name: string
    create_time: string
    mqtt_username: string
    mqtt_password: string
}

interface MemberData {
    member: Member[]
}
const columns = [
  { title: '账号', dataIndex: 'username', width: 150 },
  { title: '用户类型', dataIndex: 'user_type', width: 150, className: 'titleStyle' },
  { title: '人员名称', dataIndex: 'name', width: 150, className: 'titleStyle' },
  { title: '用户权限', dataIndex: 'user_grade', width: 150, className: 'titleStyle', slots: { customRender: 'user_grade' } },
  //   { title: '用户角色', dataIndex: 'user_role', width: 150, className: 'titleStyle' },
  //   { title: '加入方式', dataIndex: 'join_type', width: 150, className: 'titleStyle' },
  { title: '添加时间', dataIndex: 'create_time', width: 150, sorter: (a: Member, b: Member) => a.create_time.localeCompare(b.create_time), className: 'titleStyle' },
  { title: '操作', dataIndex: 'action', width: 100, className: 'titleStyle', slots: { customRender: 'action' } },
]
const rules = {
  name: [{ required: true, message: '请填写人员名称', trigger: 'blur' }],
  username: [{ required: true, message: '请填写用户账号', trigger: 'blur' }],
  password: [{ required: true, message: '请填写用户密码', trigger: 'blur' }],
  user_type: [{ required: true, message: '请选择用户类型', trigger: 'change', type: 'object' }],
  user_grade: [{ required: true, message: '请选择用户权限', trigger: 'blur' }]
  //   mqtt_username: [{ required: true, message: '请填写Mqtt账户', trigger: 'change', type: 'object' }],
  //   mqtt_password: [{ required: true, message: '请填写Mqtt密码', trigger: 'change', type: 'object' }],
}

const editableData: UnwrapRef<Record<string, Member>> = reactive({})
const pageSizeOptions = ref<string[]>(['5', '10', '20', '30', '40', '50'])

const roleList: any = ref([])// 权限列表

const route = useRoute()
let workspaceId = ''

const isEdit = ref(false)// 当前是否为编辑

const body: any = ref({
  page: 1,
  total: 0,
  page_size: 50
})

const tableData: any = ref([])

const addMemberShow = ref(false)
const delMemberShow = ref(false)
const addForm = reactive({
  name: '',
  username: '',
  password: '',
  workspace_id: workspaceId,
  user_type: '1',
  user_grade: null,
  //   mqtt_username: '',
  //   mqtt_password: '',
  // user_id: userId,

})

// 打开添加人员弹框
const addMenbersShow = () => {
  addForm.name = ''
  addForm.username = ''
  addForm.password = ''
  addForm.user_grade = null
  isEdit.value = false
  addMemberShow.value = true
}

// 获取当前权限名
const getRoleName = (text) => {
  return roleList.value.find(item => item.id == text)?.role_name || '未知'
}

// 删除角色
const delMembers = () => {
  console.log(state.selectedRowKeys)
  console.log(state.selectedRows)
  if (state.selectedRows.length === 0) {
    message.error('请选择删除数据')
  } else {
    delMemberShow.value = true
  }
}
// 删除单个角色
const deleteRow = (record: any) => {
  state.selectedRows = []
  state.selectedRowKeys = []
  state.selectedRows.push(record)
  delMemberShow.value = true
}
// 删除角色提交
const delSubmit = () => {
  const tempArr: any = []
  state.selectedRows.forEach((item: any) => {
    tempArr.push(item.user_id)
  })
  console.log(tempArr, 'tempArr')

  delUserInfo(workspaceId, tempArr).then(res => {
    if (res.code === 0) {
      message.success('删除成功！')
      getTableData()
    }
  })
  delMemberShow.value = false
}
const formRef = ref()
// 创建角色提交表单
const addFormSubmit = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      if (addForm.user_id) { // 如果有就是编辑
        updateUserInfo(workspaceId, addForm.user_id, { ...toRaw(addForm) }).then(res => {
          console.log(res, '编辑s')

          if (res.code === 0) {
            addForm.password = ''
            message.success('成功！')
            state.selectedRows = []
            getTableData()
          }
        })
      } else {
        addUserInfo(workspaceId, { ...toRaw(addForm), workspace_id: workspaceId }).then(res => {
          if (res.code === 0) {
            message.success('成功！')
            state.selectedRows = []
            getTableData()
          }
        })
      }

      addMemberShow.value = false
    } else {
      console.log('error submit!', fields)
    }
  })
}
// a-tabel操作
const state = reactive<{
    selectedRowKeys: any[];
    selectedRows: any[],
    loading: boolean;
}>({
  selectedRowKeys: [], // Check here to configure the default column
  selectedRows: [],
  loading: false,
})

// 选择表格多选框变化
const onSelectChange = (selectedRowKeys: any[], selectedRows: any[]) => {
  console.log('selectedRowKeys changed: ', selectedRowKeys)
  state.selectedRowKeys = selectedRowKeys
  state.selectedRows = selectedRows
}

const rowSelection = {
  onChange: (selectedRowKeys: (string | number)[], selectedRows: []) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
  },
  onSelect: (record: any, selected: boolean, selectedRows: []) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected: boolean, selectedRows: [], changeRows: []) => {
    console.log(selected, selectedRows, changeRows)
  },
}
type Pagination = TableState['pagination']

function refreshData (page: Pagination) {
  getTableData()
}

// 编辑数据
function edit (record: Member) {
  Object.assign(addForm, { ...record })
  isEdit.value = true
  addMemberShow.value = true

//   editableData[record.user_id] = record
}

function save (record: Member) {
  delete editableData[record.user_id]
  updateUserInfo(workspaceId, record.user_id, record).then(res => {
    if (res.code !== 0) {
      // message.error(res.message)
    }
  })
}

// 页码变化
const onShowPageChange = (current: number, pageSize: number) => {
  console.log(current, pageSize)

  body.value.page = current
  body.value.page_size = pageSize
  console.log(current, pageSize)
  getTableData()
}

// 获取所有权限角色
const getRole = () => {
  queryRole({ page: 1, pageSize: 999 }).then((res: any) => {
    roleList.value = res.data.list
  })
}

// 获取账号数据
const getTableData = () => {
  getAllUsersInfo(workspaceId, body.value).then(res => {
    const userList: any[] = res.data.list
    tableData.value = userList
    body.value.total = res.data.pagination.total
    body.value.page = res.data.pagination.page
    body.value.page_size = res.data.pagination.page_size
  })
}

onMounted(() => {
  console.log(route.query)
  workspaceId = route.query.id
  getRole()
  getTableData()
})
</script>

<style lang="scss" scoped>
.courseAll {
    margin: 20px;
    padding: 20px;
    background-color: #fff;

    .courseAll-header {
        margin-bottom: 18px;
    }

    .all {
        // background: #EEEFF4;
        padding: 15px 15px 0 0;
        height: 100%;

        .all-header {
            display: flex;
            align-items: center;
            height: 50px;
            background: #FFFFFF;
            box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.03);
            margin-bottom: 10px;
            border-radius: 5px;
            padding-left: 23px;

            .header-btn {
                height: 30px;
                padding: 0 23px;
                line-height: 30px;
                font-size: 14px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                border-radius: 5px;
                color: #FFFFFF;
                background: #3A63F3;
                margin-right: 6px;
                cursor: pointer;
            }

            .ne {
                background: rgba(58, 99, 243, 0.1);
                border: 1px solid #3A63F3;
                color: #3A63F3;
            }
        }

        .all-body {
            height: calc(100% - 70px);
        }

        .flex-display {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100% - 30px);

            .fot {
                text-align: right;
            }

            .ic {
                width: 24px;
                height: 24px;
                margin-right: 19px;
                cursor: pointer;
            }
        }

        .add-body {}
    }

}
</style>
