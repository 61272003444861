<!-- 权限管理 -->
<template>
    <div class="back">
        <div class="back-btn">
            <el-button @click="openAdd" type="primary">添加权限</el-button>
        </div>
        <el-tabs v-model="activeName" v-loading="loading" @tab-change="tabChange" tab-position="left" type="border-card"
            class="demo-tabs">
            <el-tab-pane v-for="(item, index) in authorityList " :name="index" :label="item.role_name" :key="item.id">
                <div class="all">
                    <div>
                        <div class="all-item" v-for="(func, i) in authorityFunc " :key="func.id">
                        <div class="item-title">
                            <div>{{ func.menu_name }}
                                <el-checkbox v-model="func.has_role" size="large" />
                            </div>
                            <el-button v-if="i == 0" @click="openDel" type="danger">删除权限</el-button>

                        </div>

                    </div>
                    <el-button class="all-btn" @click="confirmList" type="primary">保存设定</el-button>
                    </div>
                </div>

            </el-tab-pane>
        </el-tabs>
        <!-- 添加权限 -->
        <el-dialog :destroy-on-close="true" v-model="addShow" title="新增权限" width="600" @close="closeDialog">
            <el-form :model="addForm" label-width="auto" ref="ruleFormRef" :rules="rules">
                <el-form-item label="权限名称" prop="roleName">
                    <el-input size="large" v-model="addForm.roleName" placeholder="请输入权限名称"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addShow = false">取消</el-button>
                    <el-button class="blue" type="primary" @click="comfirmAdd(ruleFormRef)"> 确认 </el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 删除权限 -->
        <ConfirmDialog v-if="tipDialog" title="提示" :data="{ width: 600, text: 'delete' }" @confirm="delExciting"
            @close="closetip">
        </ConfirmDialog>
    </div>

</template>

<script setup lang="ts">
import { ref, onMounted, reactive } from 'vue'
import { queryRole, queryAuById, editAu, delRole, addRole } from '/@/api/right'
import { message } from 'ant-design-vue'
import ConfirmDialog from '/@/components/ConfirmDialog.vue'
import type { FormInstance, FormRules } from 'element-plus'
const activeName = ref(0)
const loading = ref(true)

let currentRoleId = 0// 当前角色id

const authorityList = ref([])// 权限角色列表
const authorityFunc = ref([])// 权限列表

// 创建权限表单
const rules = reactive<any>({
  roleName: [{ required: true, message: '请输入权限名称', trigger: 'blur' }],

})

const ruleFormRef = ref()
const addShow = ref(false)
const tipDialog = ref(false)
const addForm = ref({
  roleName: ''
})

// 打开删除
const openDel = () => {
  tipDialog.value = true
}
// 关闭删除
const closetip = () => {
  tipDialog.value = false
}
// 打开添加
const openAdd = () => {
  addShow.value = true
  addForm.value = { roleName: '' }
}
// 删除权限
const delExciting = async () => {
  const res = await delRole({
    id: currentRoleId
  })

  activeName.value = 0
  if (res.message == 'success') {
    message.success('删除成功!')
  }
  tipDialog.value = false

  getData()

  //   if (res.data.code == 200) {
  //     message.success('删除成功')
  //     getAuthority()

  //   } else {
  //     message.error(res.data.msg)
  //   }
}

// 添加角色权限

const comfirmAdd = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      const res = await addRole({
        roleName: addForm.value.roleName
      })
      console.log(res)
      if (res.message == 'success') {
        message.success('保存成功!')
      }
      addShow.value = false
      getData()
    } else {
      console.log('error submit!', fields)
    }
  })
}

// 编辑角色权限
const confirmList = async () => {
  const menuIds: any = []
  authorityFunc.value.forEach((item: any) => {
    if (item.has_role) {
      const temp: any = []
      menuIds.push(item.id)
    }
  })

  const res = await editAu({
    menuIds,
    roleId: currentRoleId
  })
  console.log(res, '编辑')

  if (res.message == 'success') {
    message.success('保存成功!')
  } else {
    message.error('失败')
  }
  activeName.value = 0
  getData()
}

// 切换tab栏
const tabChange = (index: any) => {
  loading.value = true
  activeName.value = index
  currentRoleId = authorityList.value[index].id
  getAuData()
}

// 获取角色权限列表
const getAuData = () => {
  queryAuById(currentRoleId).then(res => {
    console.log(res.data, '?')
    authorityFunc.value = res.data
    loading.value = false
  })
}

// 获取角色列表
const getData = () => {
  loading.value = true
  queryRole({ page: 1, pageSize: 10 }).then((res: any) => {
    console.log(res, '角色列表')
    authorityList.value = res.data.list
    currentRoleId = res.data.list[0].id
    getAuData()
  })
}

onMounted(() => {
  getData()
})
</script>

<style lang="scss" scoped>
.back {
    padding: 20px;

    .back-btn {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
        margin-right: 20px;
    }

    .all {
        // display: flex;
        // justify-content: space-between;

        .el-divider--horizontal {
            //分割线
            margin: 12px;
            width: 80%;
        }

        .all-item {
            .item-title {
                font-size: 20px;
                font-weight: 600;
                display: flex;
                justify-content: space-between;
            }

            .item-body {
                width: 500px;
                margin-bottom: 20px
            }
        }

        .all-btn {
            margin-top: 20px;
        }
    }
}
</style>
