import { Firmware, FirmwareQueryParam, FirmwareUploadParam } from '/@/types/device-firmware'
import request, { CommonListResponse, IListWorkspaceResponse, IPage, IWorkspaceResponse } from '/@/api/http/request'
import { Device } from '/@/types/device'

const HTTP_PREFIX = '/manage/api/v1'

// 权限相关

// 新增角色
export const addRole = async function (body: any): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/role/insert`
  const result = await request.post(url, body)
  return result.data
}
// 编辑角色
export const editRole = async function (body: any): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/role/edit`
  const result = await request.post(url, body)
  return result.data
}
// 删除角色
export const delRole = async function (body: any): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/role/del`
  const result = await request.post(url, body)
  return result.data
}
// 分页查询角色
export const queryRole = async function (body: any): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/role/queryByPage`
  const result = await request.post(url, body)
  return result.data
}
// 根据角色id查询角色权限
export const queryAuById = async function (id: any): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/roleMenu/getMenuByRoleId?roleId=${id}`
  const result = await request.get(url)
  return result.data
}
// 编辑角色权限
export const editAu = async function (body: any): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/roleMenu/editRoleMenu`
  const result = await request.post(url, body)
  return result.data
}

// 组织相关

// 新增组织
export const addOrgan = async function (body: any): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/workspaces/insert`
  const result = await request.post(url, body)
  return result.data
}
// 编辑组织
export const editOrgan = async function (body: any): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/workspaces/edit`
  const result = await request.post(url, body)
  return result.data
}
// 分页查询组织
export const queryOrgan = async function (body: any): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/workspaces/queryByPage`
  const result = await request.post(url, body)
  return result.data
}
// 删除组织
export const delOrgan = async function (body: any): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/workspaces/del`
  const result = await request.post(url, body)
  return result.data
}

// 首页统计相关

// 根据组织id和机型统计无人机数量
export const getFlyNum = async function (wid:any): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/statistics/${wid}/countDrone`
  const result = await request.post(url)
  return result.data
}
// 根据组织id和机型统计任务数量
export const getFlyJob = async function (wid:any): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/statistics/${wid}/countJob`
  const result = await request.post(url)
  return result.data
}
// 根据组织id和机型统计航线飞行数量
export const getFlyWayline = async function (wid:any, body: any): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/statistics/${wid}/countWayline`
  const result = await request.post(url, body)
  return result.data
}
